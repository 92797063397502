import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import { Field } from "formik";

const InputDate = (props) => {
    const { sm, name, load } = props;

    return (
        <Grid item xs={12} sm={sm}>
            <Field fullWidth name={name} type="date" size="small" disabled={load} format="dd/mm/yyyy" component={TextField} />
        </Grid>
    );
};

export default InputDate;
