import React, { useEffect, useContext, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Message } from "../Context/FormContext/formContext";
import { Applys } from "../Context/SearchContext/SearchModalApplymessage";
import REACT_APP from "../../environment";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import resource from "./../../i18n/resources.json";
async function abbbbb() {
    const a = await resource;
    
}
abbbbb()
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    //   height: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function TransitionsModal({ data }) {
    const [error, setError] = useState(false);
    const [toogle, setToggle] = useState(null);
    const [formMessage, setFormMessage] = useContext(Message);
    const [applyMessage, setApplyMessage] = useContext(Applys);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setApplyMessage(false);
    };
    const navigate = useNavigate();

    const getLicense = async () => {
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/applicant/resume`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setFormMessage(false);
                setToggle(false);
            })
            .catch((err) => {
                setFormMessage(true);
                setToggle(true);
            });
    };

    const applyJob = async () => {
        if (toogle === false) {
            await axios
                .post(
                    `${REACT_APP.URL}/api/hrp/v1/applicant/apply/${data.row.id}`,
                    {},
                    {
                        headers: {
                            Authorization: sessionStorage.getItem("token"),
                        },
                    }
                )
                .then((res) => {
                    setApplyMessage(true);
                })
                .catch((err) => {
                    setApplyMessage(false);
                    setError(true);
                });
        } else {
            handleClose();
            navigate("/form");
        }
    };
    useEffect(() => {
        getLicense();
        // eslint-disable-next-line
    }, []);
    const { t } = useTranslation()
    return (
        <div>
            <Button onClick={handleOpen}>{t("Read more")}</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className="container py-5 Popup">
                        {applyMessage ? (
                            <Alert variant="outlined" severity="success">
                                {i18next.t("You have successfully applied for this job")}
                            </Alert>
                        ) : (
                            ""
                        )}
                        {error ? (
                            <Alert variant="outlined" severity="warning">
                                {i18next.t("You already applyed this job")}
                            </Alert>
                        ) : (
                            ""
                        )}
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {data?.row?.name}
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            <Typography variant="h6" component="h1" style={{ display: "inline", fontSize: "18px" }}>
                                {i18next.t("Description")}:
                            </Typography>
                            {data?.row?.full_job_description}
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            <Typography variant="h6" component="h1" style={{ display: "inline", fontSize: "18px" }}>
                            {i18next.t("Employee arrangement")}:
                            </Typography>
                            {data?.row?.employee_arrangement}
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            <Typography variant="h6" component="h1" style={{ display: "inline", fontSize: "18px" }}>
                                {i18next.t("Requirement")}:
                            </Typography>
                            {data?.row?.requirement}
                        </Typography>
                        <div className="right_left">
                            <Button variant="outlined" color="success" onClick={applyJob}>
                                {i18next.t("Apply")}
                            </Button>
                            <Button variant="outlined" color="error" onClick={handleClose}>
                                {i18next.t("Close")}
                            </Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
