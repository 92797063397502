import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import LoginWithfacebook from "./components/auth/SignInWithFacebook";
import LoginWithGoogle from "./components/auth/SignInWithGoogle";
import Navbar from "./components/header/Navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignUp from "./components/auth/SignUp";
import SignIn from "./components/auth/SignIn";
import Candidate from "./pages/Candidate";
import React, { useEffect } from "react";
import Search from "./pages/SearchPage";
import FormPage from "./pages/FormPage";
import "./App.css";

function App() {
    const token = sessionStorage.getItem("token");
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`${window.location.pathname}`);
        // eslint-disable-next-line
    }, []);

    return (
        <div className="App">
            <ToastContainer />
            {token ? (
                <div>
                    <Navbar />
                    <Routes>
                        <Route path="/*" element={<Navigate to="/search" />} />
                        <Route path="/search" element={<Search />} />
                        <Route path="/candidate" element={<Candidate />} />
                        <Route path="/form" element={<FormPage />} />
                    </Routes>
                </div>
            ) : (
                <div>
                    <Routes>
                        <Route path="*" element={<Navigate to="/login" />} />
                        <Route path="/register" element={<SignUp />} />
                        {/* <Route path="/login/google" element={<LoginWithGoogle />} /> */}
                        {/* <Route path="/login/facebook" element={<LoginWithfacebook />} /> */}
                        <Route path="/login" element={<SignIn />} />
                    </Routes>
                </div>
            )}
        </div>
    );
}

export default App;
