import StepperCard from "../../../plugin/StepperCard";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import axios from "axios";
import getCookie from "../../../hooks/cookie/getCookie";
import REACT_APP from "../../../environment";
import removeCookie from "../../../hooks/cookie/removeCookie";
import { useTranslation } from 'react-i18next';

import i18next from "i18next";
import resource from "./../../../i18n/resources.json";
const Resume = () => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const secretCookie = sessionStorage?.getItem("userEmail");

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            toast.warning("Choose file!");
            return;
        } else if (file) {
            const myObject = await JSON.parse(getCookie(secretCookie));
            const body = {
                ...myObject.personal,
                work_history: { companies: myObject.workHistory },
                educations: myObject.educations,
                skills: myObject.skills,
            };
            console.log(body);
            await axios
                .post(`${REACT_APP.URL}/api/hrp/v1/applicant`, body, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                })
                .then((res) => {
                    console.log(res.data);
                    const formData = new FormData();
                    formData.append("file", file);
                    axios
                        .post(`${REACT_APP.URL}/api/hrp/v1/applicant/resume`, formData, {
                            headers: { authorization: "Bearer " + sessionStorage.getItem("token"), "Content-Type": "multipart/form-data" },
                        })
                        .then((res) => {
                            setLoading(false);
                            setFile(null);
                            toast.success(i18next.t("Resume uploaded successfully!"));
                            removeCookie(secretCookie);
                        })
                        .catch((err) => {
                            setLoading(false);
                            toast.error(i18next.t(`The first forms need to be filled out and sent`));
                        });
                })
                .catch((err) => {
                    console.log(err);
                    // toast.error(err.response.data.errors[0].message);
                    toast.error(i18next.t("An error occurred"));
                    setLoading(false);
                });
        } else return toast.error(i18next.t("An error occurred"));
    };
    const {t}=useTranslation()

    return (
        <StepperCard title={t("Resume")}>
            <form onSubmit={onSubmit}>
                <div className="right-left">
                    <div>
                        <Button variant="contained" component="label">
                            {t("Select resume")}
                            <input onChange={(e) => setFile(e.target.files[0])} hidden accept="image/*" type="file" />
                        </Button>
                        <span className="ms-2">{file?.name}</span>
                    </div>
                    <Button type="submit" variant="contained" color="success" disabled={file ? false : true}>
                        {t("Submit resume")}
                    </Button>
                </div>
            </form>
        </StepperCard>
    );
};

export default Resume;
