import { Grid } from "@mui/material";
import setCookie from "../../../hooks/cookie/setCookie";
import getCookie from "../../../hooks/cookie/getCookie";
import StepperCard from "../../../plugin/StepperCard";
import ErrorsField from "../../../plugin/ErrorsField";
import { workHistorySchema } from "../validateSchema";
import { Field, FieldArray, Form, Formik } from "formik";
import InputDate from "../../../plugin/InputDate";
import Buttons from "../../../plugin/Button";
import Input from "../../../plugin/Input";
import { emptyWork } from "../EmptyData";
import React, { useState } from "react";
import { useEffect } from "react";
import { CheckboxWithLabel } from "formik-material-ui";
import { useTranslation } from "react-i18next";

export default function WorkHistory({ next }) {
    const [loading, setLoading] = useState(false);
    const secretCookie = sessionStorage?.getItem("userEmail");
    const myObject = JSON.parse(getCookie(secretCookie));
    const { t } = useTranslation();

    const e_name = (id, st) => `work[${id}].${st}`;
    const e_name2 = (id1, id2, st) => `work[${id1}].references[${id2}].${st}`;

    const submit = async (values) => {
        setLoading(true);
        values.work.forEach((el, index) => {
            if (!el.website) el.website = undefined;
            if (!el.zip_code) el.zip_code = undefined;
            if (!el.address2) el.address2 = undefined;
            if (!el.is_complete) {
                el.supervisor = undefined;
                el.references = undefined;
            }
            el.is_complete = undefined;
        });
        myObject.workHistory = values.work;
        setCookie(secretCookie, JSON.stringify(myObject));
        setTimeout(() => {
            setLoading(false);
            next();
        }, 1000);
    };

    useEffect(() => {
        const myObject = JSON.parse(getCookie(secretCookie));
        if (myObject.workHistory) next();
    }, []);

    return (
        <StepperCard title={t("Work History")}>
            <Formik initialValues={{ work: [emptyWork] }} validationSchema={workHistorySchema} onSubmit={(values) => submit(values)}>
                {({ values, errors }) => (
                    <Form autoComplete="off">
                        <Grid container direction="column" spacing={2}>
                            <FieldArray name="work">
                                {({ push, remove }) => (
                                    <>
                                        {values.work.map((item, index) => (
                                            <Grid item container spacing={2} xs={12} key={index}>
                                                {index !== 0 && (
                                                    <Grid item xs={12}>
                                                        <p className="text-center fw-bold my-3">
                                                            {t("Work History")} {index + 1}
                                                        </p>
                                                    </Grid>
                                                )}
                                                <Input sm={4} name={e_name(index, "name")} label={t("Name")} load={loading} />
                                                <Input sm={4} name={e_name(index, "position")} label={t("Position")} load={loading} />
                                                <Input sm={4} name={e_name(index, "phone")} label={t("Phone")} load={loading} />
                                                <Input sm={3} name={e_name(index, "email")} label={t("Email")} load={loading} />
                                                <Input sm={3} name={e_name(index, "website")} label={t("Website")} load={loading} />
                                                <InputDate sm={3} name={e_name(index, "start_date")} load={loading} />
                                                <InputDate sm={3} name={e_name(index, "end_date")} load={loading} />

                                                <Grid item xs={12}>
                                                    <h6>{t("Address")}</h6>
                                                </Grid>
                                                <Input sm={4} name={e_name(index, "address.city")} label={t("City")} load={loading} />
                                                <Input sm={4} name={e_name(index, "address.region")} label={t("Region")} load={loading} />
                                                <Input sm={4} name={e_name(index, "address.country")} label={t("Country")} load={loading} />
                                                <Input sm={4} name={e_name(index, "address.zip_code")} label={t("Zip Code")} load={loading} />
                                                <Input sm={4} name={e_name(index, "address.address1")} label={t("Address 1")} load={loading} />
                                                <Input sm={4} name={e_name(index, "address.address2")} label={t("Address 2")} load={loading} />

                                                <Grid item xs={12}>
                                                    <Field
                                                        type="checkbox"
                                                        disabled={loading}
                                                        component={CheckboxWithLabel}
                                                        name={e_name(index, "is_complete")}
                                                        Label={{ label: t("Do you want to complete Supervisor and References fields?") }}
                                                    />
                                                </Grid>

                                                {item.is_complete && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <h6>{t("Supervisor")}</h6>
                                                        </Grid>
                                                        <Input sm={3} name={e_name(index, "supervisor.name")} label={t("Name")} load={loading} />
                                                        <Input sm={3} name={e_name(index, "supervisor.position")} label={t("Position")} load={loading} />
                                                        <Input sm={3} name={e_name(index, "supervisor.phone")} label={t("Phone")} load={loading} />
                                                        <Input sm={3} name={e_name(index, "supervisor.email")} label={t("Email")} load={loading} />

                                                        <Grid item xs={12}>
                                                            <h6>{t("References")}</h6>
                                                        </Grid>
                                                        {item.references.map((subval, idx) => (
                                                            <React.Fragment key={idx}>
                                                                <Input sm={6} name={e_name2(index, idx, "first_name")} label={t("First Name")} load={loading} />
                                                                <Input sm={6} name={e_name2(index, idx, "last_name")} label={t("Last Name")} load={loading} />
                                                                <Input sm={4} name={e_name2(index, idx, "position")} label={t("Position")} load={loading} />
                                                                <Input sm={4} name={e_name2(index, idx, "email")} label={t("Email")} load={loading} />
                                                                <Input sm={4} name={e_name2(index, idx, "phone")} label={t("Phone")} load={loading} />
                                                            </React.Fragment>
                                                        ))}
                                                    </>
                                                )}
                                                {index !== 0 && <Buttons st={false} color="error" click={() => remove(index)} name={t("Delete")} load={loading} />}
                                            </Grid>
                                        ))}
                                        <ErrorsField error={errors.work} />
                                        <Grid item container spacing={2} xs={12}>
                                            <Buttons sm={6} st={false} click={() => push(emptyWork)} name={t("Add")} load={loading} />
                                            <Buttons sm={6} type="submit" name={t("Save")} cls="float-end" load={loading} />
                                        </Grid>
                                    </>
                                )}
                            </FieldArray>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </StepperCard>
    );
}
