import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import { Field } from "formik";

const Input = (props) => {
    const { sm, name, label, load, val } = props;

    return (
        <Grid item xs={12} sm={sm}>
            <Field fullWidth size="small" name={name} value={val} label={label} disabled={load} component={TextField} />
        </Grid>
    );
};

export default Input;
