import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import REACT_APP from "../../environment";
import axios from "axios";
import Spinner from "react-spinner-material";
import { ToastContainer, toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import resource from "./../../i18n/resources.json";
async function abbbbb(){
    const a=await resource;
}
abbbbb()
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: "id", numeric: false, label: i18next.t("Job Id") },
    { id: "title", numeric: true, label: i18next.t("Job title") },
    { id: "date", numeric: true, label: i18next.t("Applied date") },
    { id: "status", numeric: true, label: i18next.t("Status") },
    {
        id: "action",
        numeric: true,
        label: "Action",
        renderCell: (id) => {
            return (
                <>
                    <div>{id}</div>
                </>
            );
        },
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
    const { t } = useTranslation();
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [formCompleted, setFormCompleted] = React.useState(null);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [page, setPage] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    React.useEffect(() => {
        getDate();
    }, []);

    const getDate = async () => {
        setLoading(true);
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/applicant/positions`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setData(res.data.data.applied_positions);
                setLoading(false);
                setFormCompleted(true);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                if (err.response.status === 401) return setFormCompleted(false);
                setError(true);
                // toast.error(t("Request failed with status code 401"));
                toast.error(i18next.t("An error occurred"));
            });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const onWithdraw = async (id) => {
        // const toggle = window.confirm("Delete withdraw");
        // if (toggle) {
        await axios
            .delete(`${REACT_APP.URL}/api/hrp/v1/applicant/withdraw/${id}`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                // toast.success("withdrawed successfully");
                toast.error(i18next.t("An error occurred"));
                getDate();
            })
            .catch((err) => {
                // toast.error("withdraw is not working");
                toast.error(i18next.t("An error occurred"));
            });
        // }
    };

    return (
        <div className="container py-5">
            <ToastContainer />
            {loading ? (
                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <Box sx={{ width: "100%" }}>
                    {formCompleted === false ? (
                        <Alert variant="outlined" severity="warning">
                            {t("The form must be completed. Go to the form page and submit the form")}
                        </Alert>
                    ) : !error && data.length === 0 ? (
                        <Alert variant="outlined" severity="warning">
                            {t("You haven't submitted any jobs yet")}
                        </Alert>
                    ) : error ? (
                        <Alert variant="outlined" severity="error">
                            {t("An error occurred")}
                        </Alert>
                    ) : (
                        data.length >= 1 && (
                            <Paper sx={{ width: "100%", mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={data.length} />
                                        <TableBody>
                                            {stableSort(data, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    return (
                                                        <TableRow hover tabIndex={-1} key={row.id}>
                                                            <TableCell align="left">{row.id}</TableCell>
                                                            <TableCell align="left">{row.position.name}</TableCell>
                                                            <TableCell align="left">{row.applied_date?.slice(0, 10)}</TableCell>
                                                            <TableCell align="left">{row.status}</TableCell>
                                                            <TableCell align="left">
                                                                <Button size="small" variant="outlined" onClick={() => onWithdraw(row.id)}>
                                                                    withdraw
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        )
                    )}
                </Box>
            )}
        </div>
    );
}
