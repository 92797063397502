import { Button, CircularProgress, Grid } from "@mui/material";

const Buttons = (props) => {
    const { sm, name, load, click, cls, type, st = true, color } = props;

    return (
        <Grid item xs={12} sm={sm ? sm : 12}>
            <Button
                type={type}
                color={color}
                disabled={load}
                className={cls}
                onClick={click}
                variant="contained"
                startIcon={st ? load && <CircularProgress size="0.9rem" /> : null}
            >
                {name}
            </Button>
        </Grid>
    );
};

export default Buttons;
