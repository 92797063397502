import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { MassageProvider } from "./components/Context/FormContext/formContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./i18n"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
        <BrowserRouter>
            <GoogleOAuthProvider clientId="189128516991-jp95hi59rst3qft8rmcqemghiikailkc.apps.googleusercontent.com">
                <MassageProvider>
                    <App />
                </MassageProvider>
            </GoogleOAuthProvider>
        </BrowserRouter>
    </>
);
