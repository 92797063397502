import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import REACT_APP from "../../environment";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

import i18next from "i18next";
import resource from "./../../i18n/resources.json";
await resource;
const theme = createTheme();

export default function SignUp() {
    let navigate = useNavigate();
    const{t}=useTranslation()

    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token");

    const [loading, setLoading] = React.useState(false);
    const [visibleIcon, setVisibleIcon] = React.useState(true);
    const [visibleIcon2, setVisibleIcon2] = React.useState(true);

    const validateSignUp = Yup.object({
        email: Yup.string().email("Email is invalid").required(t("Email is required")),
        password: Yup.string().min(6, "Password must be at least 6 charaters").required(t("Password is required")),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), null], "Password must match")
            .required(t("Confirm password is required")),
    });

    const handleSubmit = (event) => {
        setLoading(true);
        axios
            .post(
                `${REACT_APP.AUTH}/api/hrp/v2/applicant/signup`,
                {
                    email: event.email,
                    password: event.password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            )
            .then((res) => {
                navigate("/login");
                setLoading(false);
            })
            .catch((err) => {
                // toast.error(err.response.data.errors[0].message);
                toast.error(i18next.t("An error occurred"));

                setLoading(false);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <ToastContainer />
            {loading ? <div className="hrp-overlay"></div> : null}
            <Container component="main" maxWidth="xs" className="py-5">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t("Sign up")}
                    </Typography>

                    <Formik
                        initialValues={{ email: "", password: "", confirmPassword: "" }}
                        validationSchema={validateSignUp}
                        onSubmit={async (values) => {
                            handleSubmit(values);
                            return new Promise((res) => setTimeout(res, 500));
                        }}
                    >
                        {({ values, errors }) => (
                            <Form autoComplete="off" className="mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field fullWidth name="email" label={t("Email Address")} autoComplete="email" component={TextField} disabled={loading} />
                                    </Grid>

                                    <Grid item xs={12} className="input_div">
                                        <Field
                                            fullWidth
                                            label={t("Password")}
                                            name="password"
                                            component={TextField}
                                            autoComplete="password"
                                            type={visibleIcon ? "password" : "text"}
                                            disabled={loading}
                                        />
                                        {visibleIcon ? (
                                            <VisibilityOffIcon onClick={() => setVisibleIcon((prev) => !prev)} />
                                        ) : (
                                            <RemoveRedEyeIcon onClick={() => setVisibleIcon((prev) => !prev)} />
                                        )}
                                    </Grid>

                                    <Grid item xs={12} className="input_div">
                                        <Field
                                            fullWidth
                                            name="confirmPassword"
                                            label={t("Confirm password")}
                                            component={TextField}
                                            type={visibleIcon2 ? "password" : "text"}
                                            autoComplete="new password"
                                            disabled={loading}
                                        />
                                        {visibleIcon2 ? (
                                            <VisibilityOffIcon onClick={() => setVisibleIcon2((prev) => !prev)} />
                                        ) : (
                                            <RemoveRedEyeIcon onClick={() => setVisibleIcon2((prev) => !prev)} />
                                        )}
                                    </Grid>
                                </Grid>
                                <Button type="submit" fullWidth className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} disabled={loading}>
                                    {t("Sign up")}
                                </Button>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link to="/login" variant="body2" disabled={loading}>
                                            {t("Already have an account? Sign in")}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
