import * as React from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import WorkHistory from "./WorkHistory";
import Education from "./Education";
import Resume from "./Resume.jsx";
import Personal from "./Personal";
import Skill from "./Skill";

import i18next from "i18next";
import resource from "./../../../i18n/resources.json";
await resource;

const steps = [i18next.t("Personal"), i18next.t("Work History"), i18next.t("Education"), i18next.t("Skills"), i18next.t("Resume")];

export default function OnboardingStepper() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [components, setComponents] = React.useState([]);
    const [skipped, setSkipped] = React.useState(new Set());

    React.useEffect(() => {
        setComponents([
            <Personal next={handleNext} />,
            <WorkHistory next={handleNext} />,
            <Education next={handleNext} />,
            <Skill next={handleNext} />,
            <Resume next={handleNext} />,
        ]);
    }, []);

    const isStepSkipped = (step) => skipped.has(step);

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    return (
        <Box sx={{ width: "100%" }} className="container py-5">
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) stepProps.completed = false;
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <Typography sx={{ my: 20, color: "success.main", fontWeight: "bold", fontSize: "18px" }} className="text-center">
                    All steps completed - you&apos;re finished
                </Typography>
            ) : (
                <Typography sx={{ mt: 2, mb: 1 }}>{components[activeStep]}</Typography>
            )}
        </Box>
    );
}
