import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Spinner from "react-spinner-material";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import saveAs from "file-saver";
import axios from "axios";
import REACT_APP from "../../environment";
import getCookie from "../../hooks/cookie/getCookie";

import i18next from "i18next";
import resource from "./../../i18n/resources.json";
await resource;
const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "95%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const Upload = () => {
    const [save, setSave] = useState(false);
    const [resume, setResume] = useState(false);
    const [updateRes, setUpdateRes] = useState("");
    const [toggle, setToggle] = useState(false);
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setloading] = useState(false);
    const [unexpectedfile, setunexpectedfile] = useState(false);
    const [opened, setOpened] = React.useState(false);
    const handleOpened = () => setOpened(!opened);
    const handleClosed = () => setOpened(!opened);

    const myObject = JSON.parse(getCookie("applicantValue"));
    console.log(myObject);

    const CkeckResume = async () => {
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/applicant/resume`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setResume(true);
                setFile(res.data);
            })
            .catch((err) => setResume(false));
    };

    const getFile = () => {
        setloading(true);
        setError(false);
        setunexpectedfile(false);

        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/applicant/resume`, {
                responseType: "blob",
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((response) => {
                setToggle(true);
                setError(false);
                setloading(false);
                const reader = new FileReader();
                reader.onload = (event) => {
                    if (response.data.type === "application/pdf" || /image/.test(response.data.type)) {
                        setFile(response.data);
                        setunexpectedfile(false);
                        window.document.getElementById("test").src = `${event.target.result}`;
                    } else {
                        setunexpectedfile(true);
                        saveAs(response.data);
                    }
                };
                reader.readAsDataURL(response.data);
            })
            .catch((err) => {
                setError(true);
                setloading(false);
            });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!file) {
            toast.warning("Choose file!");
            return;
        } else if (file) {
            const formData = new FormData();
            formData.append("file", file);
            axios
                .post(`${REACT_APP.URL}/api/hrp/v1/applicant/resume`, formData, {
                    headers: {
                        authorization: "Bearer " + sessionStorage.getItem("token"),
                        "Content-Type": "multipart/form-data",
                    },
                    setToggle,
                })
                .then((res) => {
                    setFile(null);
                    toast.success(i18next.t("Resume uploaded successfully!"));
                    setToggle(!toggle);
                    CkeckResume();
                })
                .catch((err) => {
                    // toast.error(`The first forms need to be filled out and sent`);
                    // toast.error("An error occurred");
                    toast.error(i18next.t("An error occurred"));

                });
        } else {
            return toast.error(i18next.t("An error occurred"));
        }
    };

    const onUpdateSubmit = (e) => {
        e.preventDefault();
        if (!updateRes) {
            toast.warning("Choose file!");
            setSave(true);
            return;
        } else if (updateRes) {
            setSave(true);
            const formData = new FormData();
            formData.append("file", updateRes);
            axios
                .put(`${REACT_APP.URL}/api/hrp/v1/applicant/resume`, formData, {
                    headers: {
                        authorization: "Bearer " + sessionStorage.getItem("token"),
                        "Content-Type": "multipart/form-data",
                    },
                    setToggle,
                })
                .then((res) => {
                    setSave(false);
                    setUpdateRes(null);
                    toast.success(i18next.t("Resume updated successfully!"));
                })
                .catch((err) => {
                    setSave(false);
                    // toast.error(`An error occurred`);
                    toast.error(i18next.t("An error occurred"));
                });
        } else {
            return toast.error(i18next.t("An error occurred"));
        }
    };

    useEffect(() => {
        CkeckResume();
    }, [resume]);

    return (
        <div>
            <ToastContainer />
            {resume ? (
                <div className="d-flex justify-content-evenly m-3 view_wrapper">
                    <div className="d-flex justify-content-center align-items-center">
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                                handleOpened();
                                getFile();
                            }}
                        >
                            View resume
                        </Button>
                        <Modal
                            keepMounted
                            open={opened}
                            onClose={handleClosed}
                            aria-labelledby="keep-mounted-modal-title"
                            aria-describedby="keep-mounted-modal-description"
                        >
                            <Box className="modal_box" sx={style} style={{ display: "flex", flexDirection: "column" }}>
                                {unexpectedfile && <h2 className="title_download">File downloaded</h2>}
                                {error && <h2 className="title_download">An error occurred</h2>}
                                {loading && (
                                    <div className="d-flex justify-content-center">
                                        <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                                    </div>
                                )}
                                {!loading && !unexpectedfile && !error && (
                                    <iframe
                                        style={{
                                            height: "calc(100% - 30px)",
                                            minHeight: "80vh",
                                        }}
                                        // src={file}
                                        id="test"
                                        title="test"
                                    ></iframe>
                                )}
                                {!loading && (
                                    <button onClick={handleClosed} className="btn btn-danger">
                                        Close
                                    </button>
                                )}
                            </Box>
                        </Modal>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="right-left">
                            <Button variant="contained" component="label">
                                Select resume
                                <input onChange={(e) => setUpdateRes(e.target.files[0])} hidden accept="image/*" type="file" />
                            </Button>
                            <span>{updateRes?.name}</span>
                        </div>
                        <div className="d-flex justify-content-center align-items-center margin_left">
                            {save ? (
                                <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                                    Update resume
                                </LoadingButton>
                            ) : (
                                <Button onClick={onUpdateSubmit} type="submit" variant="contained" color="success" disabled={updateRes ? false : true}>
                                    Update resume
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <form onSubmit={onSubmit}>
                        <div className="right-left">
                            <div>
                                <Button variant="contained" component="label">
                                    Select resume
                                    <input onChange={(e) => setFile(e.target.files[0])} hidden accept="image/*" type="file" />
                                </Button>
                                <span>{file?.name}</span>
                            </div>
                            <Button type="submit" variant="contained" color="success" disabled={file ? false : true}>
                                Submit resume
                            </Button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Upload;
