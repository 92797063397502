/* eslint-disable no-unused-expressions */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoginSocialFacebook } from "reactjs-social-login";
import FacebookIcon from "@mui/icons-material/Facebook";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleIcon from "@mui/icons-material/Google";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { TextField } from "formik-material-ui";
import { Field, Form, Formik } from "formik";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import REACT_APP from "../../environment";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import resource from "./../../i18n/resources.json";
await resource;
const theme = createTheme();

export default function Login() {
    const{t}=useTranslation()

    const [user, setUser] = useState([]);

    const loginGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => {},
    });

    const validateSignIn = Yup.object({
        email: Yup.string().email("Email is invalid").required(t("Email is required")),
        password: Yup.string().min(6, "Password must be at least 6 charaters").required(t("Password is required")),
    });

    const [visibleIcon, setVisibleIcon] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        setLoading(true);
        axios
            .post(`${REACT_APP.AUTH}/api/hrp/v2/applicant/login`, {
                email: event.email,
                password: event.password,
            })
            .then((res) => {
                sessionStorage.setItem("token", res.data?.data?.token);
                sessionStorage.setItem("userEmail", event.email);
                navigate("/");
                setLoading(false);
            })
            .catch((err) => {
                // toast.error(err.response.data.errors[0].message);
                toast.error(i18next.t("An error occurred"));
                
                setLoading(false);
            });
    };

    const rejectFacebook = async (err) => {
        // toast.error("Login failed");
        toast.error(i18next.t("An error occurred"));
    };
    const loginFacebook = async (response) => {
        localStorage.setItem("profile", JSON.stringify(response));
        window.location = "/login/facebook";
    };

    useEffect(() => {
        if (user) {
            axios(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    Accept: "application/json",
                },
            })
                .then((res) => {
                    localStorage.setItem("profile", JSON.stringify({ provider: "google", data: res.data }));
                    sessionStorage.setItem("userEmail", res.data.email);
                    window.location = "/login/google";
                })
                .catch((err) => {});
        }
    }, [user]);

    return (
        <ThemeProvider theme={theme}>
            <ToastContainer />
            {loading ? <div className="hrp-overlay"></div> : null}
            <Container component="main" maxWidth="xs" className="py-5">
                <CssBaseline />
                <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t("Sign In")}
                    </Typography>
                    <Formik validationSchema={validateSignIn} initialValues={{ email: "", password: "" }} onSubmit={async (values) => handleSubmit(values)}>
                        {({ values, errors }) => (
                            <Form autoComplete="off" className="mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field fullWidth name="email" label={t("Email Address")} autoComplete="email" component={TextField} disabled={loading} />
                                    </Grid>
                                    <Grid item xs={12} className="input_div">
                                        <Field
                                            fullWidth
                                            label={t("Password")}
                                            name="password"
                                            component={TextField}
                                            autoComplete="password"
                                            type={visibleIcon ? "password" : "text"}
                                            disabled={loading}
                                        />
                                        {visibleIcon ? (
                                            <VisibilityOffIcon onClick={() => setVisibleIcon((prev) => !prev)} />
                                        ) : (
                                            <RemoveRedEyeIcon onClick={() => setVisibleIcon((prev) => !prev)} />
                                        )}
                                    </Grid>
                                </Grid>

                                <Button fullWidth type="submit" className="mt-4" variant="contained" sx={{ mt: 2, mb: 2 }} disabled={loading}>
                                    {t("Sign In")}
                                </Button>
                                {/* <Grid className="login_social_wrapper">
                                    <Grid item className="pointer">
                                        <Button
                                            fullWidth
                                            color="error"
                                            className="mt-4"
                                            variant="outlined"
                                            sx={{ mt: 2, mb: 2 }}
                                            onClick={loginGoogle}
                                            disabled={loading}
                                        >
                                            <GoogleIcon className="me-3" fontSize="medium" />
                                            {t("Sign In with google")}
                                        </Button>
                                    </Grid>
                                    <Grid item className="pointer">
                                        <Button fullWidth className="mt-1" variant="outlined" sx={{ mt: 2, mb: 2 }} disabled={loading}>
                                            <LoginSocialFacebook
                                                appId="622136183091538"
                                                onReject={(err) => {
                                                    rejectFacebook(err);
                                                }}
                                                onResolve={(response) => {
                                                    loginFacebook(response);
                                                }}
                                            >
                                                <FacebookIcon className="me-3" fontSize="medium" />
                                                {t("Sign In with facebook")}
                                            </LoginSocialFacebook>
                                        </Button>
                                    </Grid>
                                </Grid> */}
                                <Grid container>
                                    <Grid item>
                                        <Link to="/register" variant="body2" disabled={loading}>
                                            {t("Don't have an account? Sign Up")}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
