import setCookie from "../../../hooks/cookie/setCookie";
import getCookie from "../../../hooks/cookie/getCookie";
import ErrorsField from "../../../plugin/ErrorsField";
import StepperCard from "../../../plugin/StepperCard";
import { educationSchema } from "../validateSchema";
import { FieldArray, Form, Formik } from "formik";
import InputDate from "../../../plugin/InputDate";
import { emptyEducation } from "../EmptyData";
import Buttons from "../../../plugin/Button";
import Select from "../../../plugin/Select";
import Input from "../../../plugin/Input";
import { toast } from "react-toastify";
import { Grid, MenuItem } from "@mui/material";
import { useEffect } from "react";
import REACT_APP from "../../../environment";
import { useState } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import resource from "./../../../i18n/resources.json";
const complete_date = [
    { id: false, name: "false" },
    { id: true, name: "true" },
];

export default function Education({ next }) {
    const { t } = useTranslation();
    const [univers, setUnivers] = useState([]);
    const [loading, setLoading] = useState(false);
    const secretCookie = sessionStorage?.getItem("userEmail");
    const myObject = JSON.parse(getCookie(secretCookie));
    console.log(myObject);

    const e_name = (id, st) => `educations[${id}].${st}`;

    const getUniversity = () => {
        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/applicant/universities`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => setUnivers(res.data.data.universities))
            .catch((err) => toast.error(i18next.t("An error occurred")));
    };

    const submit = async (values) => {
        setLoading(true);
        values.educations.forEach((el, index) => {
            if (!el.end_date || values.educations[0]?.is_complete === "false") el.end_date = undefined;
        });
        myObject.educations = values.educations;
        setCookie(secretCookie, JSON.stringify(myObject));
        setTimeout(() => {
            setLoading(false);
            next();
        }, 1000);
    };

    useEffect(() => {
        const myObject = JSON.parse(getCookie(secretCookie));
        if (myObject.educations) next();

        getUniversity();
    }, []);

    return (
        <StepperCard title={t("Education")}>
            <Formik initialValues={{ educations: [emptyEducation] }} validationSchema={educationSchema} onSubmit={(values) => submit(values)}>
                {({ values, errors }) => (
                    <Form autoComplete="off">
                        <Grid container direction="column" spacing={2}>
                            <FieldArray name="educations">
                                {({ push, remove }) => (
                                    <>
                                        {values.educations.map((_, index) => (
                                            <Grid item container spacing={2} xs={12}>
                                                {index !== 0 && (
                                                    <Grid item xs={12}>
                                                        <p className="text-center fw-bold">
                                                            {t("Education")} {index + 1}
                                                        </p>
                                                    </Grid>
                                                )}
                                                <Select
                                                    sm={6}
                                                    name={e_name(index, "university")}
                                                    label={t("Select your university")}
                                                    option={univers}
                                                    load={loading}
                                                />
                                                <Input sm={6} name={e_name(index, "years_of_edu")} label={t("Years of education")} load={loading} />
                                                <Input sm={6} name={e_name(index, "major")} label={t("Major")} load={loading} />
                                                <Input sm={6} name={e_name(index, "degree_type")} label={t("Your degree")} load={loading} />
                                                <InputDate sm={4} name={e_name(index, "start_date")} load={loading} />
                                                <InputDate sm={4} name={e_name(index, "end_date")} load={loading} />
                                                <Select sm={4} name={e_name(index, "is_complete")} label={t("Is completed")} option={complete_date} load={loading} />
                                                {index !== 0 && <Buttons st={false} color="error" click={() => remove(index)} name={t("Delete")} load={loading} />}


                                            </Grid>
                                        ))}
                                        <ErrorsField error={errors.educations} />
                                        <Grid item container spacing={2} xs={12}>
                                            <Buttons sm={6} st={false} click={() => push(emptyEducation)} name={t("Add")} load={loading} />
                                            <Buttons sm={6} type="submit" name={t("Save")} cls="float-end" load={loading} />
                                        </Grid>
                                    </>
                                )}
                            </FieldArray>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </StepperCard>
    );
}
