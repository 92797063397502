export const applicant = {
    middle_name: "",
    first_name: "",
    last_name: "",
    phone: "",
};

export const emptySkill = { name: "", level: "" };

export const emptyEducation = {
    major: "",
    years_of_edu: "",
    start_date: "",
    end_date: null,
    is_complete: "",
    degree_type: "",
    university: "",
};

const reference = {
    first_name: "",
    last_name: "",
    position: "",
    email: "",
    phone: "",
};
const emptyReferences = [reference, reference];

const emptyWorkSupervisor = {
    name: "",
    position: "",
    phone: "",
    email: "",
    is_complete: true,
};
const emptyWorkAddress = {
    city: "",
    region: "",
    country: "",
    zip_code: "",
    address1: "",
    address2: "",
};
export const emptyWork = {
    name: "",
    position: "",
    phone: "",
    email: "",
    website: "",
    start_date: "",
    end_date: "",
    is_complete: false,
    supervisor: emptyWorkSupervisor,
    address: emptyWorkAddress,
    references: emptyReferences,
};
