import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { DataGrid } from "@mui/x-data-grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Popup from "./Popup";
import REACT_APP from "../../environment";
import { ApplyProvider } from "../Context/SearchContext/SearchModalApplymessage";
import { toast, ToastContainer } from "react-toastify";
import i18next from "i18next";
import resource from "./../../i18n/resources.json";
import { Container, Item, FormControlWrapper, Input, OptionsWrap } from "./style";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";
async function abbbbb() {
    const a =await resource;
}
await abbbbb()
const columns = [
    { field: "id", headerName: "ID", width: 100, align: "center" },
    { field: i18next.t("city"), headerName: i18next.t("City"), width: 200, align: "center" },
    {
        field: "country",
        headerName: i18next.t("Country"),
        type: "number",
        width: 200,
        align: "center",
    },
    {
        field: "job_type",
        headerName: i18next.t("Job type"),
        type: "number",
        width: 200,
        align: "center",
    },
    {
        field: "name",
        headerName: i18next.t("Name"),
        type: "number",
        width: 200,
        align: "center",
    },
    {
        field: "type",
        headerName: i18next.t("Type"),
        type: "number",
        width: 200,
        align: "center",
    },
    {
        field: "action",
        headerName: i18next.t("action"),
        width: 200,
        align: "center",
        renderCell: (data) => {
            return (
                <>
                    <div>
                        <Popup data={data} />
                    </div>
                </>
            );
        },
    },
];

const Search = () => {
    const { t } = useTranslation();
    const [categoryValue, setCategoryValue] = React.useState("");
    const [location, setLocation] = React.useState("");
    const [time, setTime] = React.useState("");
    const [category, setCategory] = React.useState([]);
    const [par, setPar] = useState("");
    const [loading, setLoading] = useState(false);

    const handleChange1 = (event) => {
        setLocation(event.target.value);
    };
    const handleChange2 = (event) => {
        setTime(event.target.value);
    };
    const handleChange3 = (e) => {
        setCategoryValue(e.target.value);
    };

    const [data, setData] = useState([]);

    const onChange = (e) => {
        setPar(e.target.value);
    };

    const getCotegory = () => {
        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/openPosition/job_types`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setCategory(res.data.data.jobTypes);
            })
            .catch((err) => {
                toast.error(i18next.t("An error occurred"));
                toast.error(i18next.t("There was an error getting job types"));
            });
    };

    const onSearch = () => {
        if (!(regex.address1.test(location) || location === "")) {
            toast.warning(i18next.t("Location value invalid"));
        } else if (par && location && time && categoryValue) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?query=${par}&loc=${location}&emp=${time}&type=${categoryValue}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (par && location && time) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?query=${par}&loc=${location}&emp=${time}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (categoryValue && location && time) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?loc=${location}&emp=${time}&type=${categoryValue}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (par && time && categoryValue) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?query=${par}&emp=${time}&type=${categoryValue}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (par && location && categoryValue) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?query=${par}&loc=${location}&type=${categoryValue}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (par && location) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?query=${par}&loc=${location}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (par && time) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?query=${par}&emp=${time}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (par && categoryValue) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?query=${par}&type=${categoryValue}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (location && time) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?loc=${location}&emp=${time}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (location && categoryValue) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?loc=${location}&type=${categoryValue}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (time && categoryValue) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?emp=${time}&type=${categoryValue}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (par) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?query=${par}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (location) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?loc=${location}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (time) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?emp=${time}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else if (categoryValue) {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job?type=${categoryValue}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            axios
                .get(`${REACT_APP.URL}/api/hrp/v1/search/job`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data.jobs);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        getCotegory();
    }, []);

    return (
        <ApplyProvider>
            <Container>
                <ToastContainer />
                {loading ? <div className="hrp-overlay"></div> : null}
                <Item input>
                    <Input className="form-control" placeholder={t("Search jobs...")} onChange={onChange} />
                    {loading ? (
                        <LoadingButton loading variant="outlined" style={{ marginLeft: "10px", marginRight: "10px" }}>
                            {t("Search")}
                        </LoadingButton>
                    ) : (
                        <Button
                            disabled={par || location || time || categoryValue ? false : true}
                            onClick={onSearch}
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                            variant="outlined"
                            size="large"
                        >
                            {t("Search")}
                        </Button>
                    )}
                </Item>
                <Item>
                    <OptionsWrap>
                        <FormControlWrapper>
                            <FormControl fullWidth>
                                <Input
                                    value={location}
                                    className="form-control search_location_input"
                                    placeholder={t("Location...")}
                                    onChange={handleChange1}
                                />
                            </FormControl>
                        </FormControlWrapper>
                        <FormControlWrapper>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{t("Part Time / Full Time")}</InputLabel>
                                <Select
                                    labethd="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={time}
                                    // label="Age"
                                    onChange={handleChange2}
                                >
                                    <MenuItem value="part-time">{t("Part Time")}</MenuItem>
                                    <MenuItem value="full-time">{t("Full Time")}</MenuItem>
                                    <MenuItem value="remote">{t("Remote")}</MenuItem>
                                    <MenuItem value="gibrit">{t("Gibrit")}</MenuItem>
                                </Select>
                            </FormControl>
                        </FormControlWrapper>
                        <FormControlWrapper>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{t("Job category")}</InputLabel>
                                <Select
                                    labethd="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={categoryValue}
                                    // label="Age"
                                    onChange={(e) => handleChange3(e)}
                                >
                                    {category.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                    {/* <MenuItem value="IT">IT</MenuItem> */}
                                </Select>
                            </FormControl>
                        </FormControlWrapper>
                    </OptionsWrap>
                </Item>
                <Item table>
                    {data?.length > 0 && <h3 style={{ background: "white" }}>{t("Found jobs")}: {data?.length ? data?.length : "not data"}</h3>}
                    {data?.length ? (
                        <div>
                            <div
                                className="DataGrid_wrapper"
                                style={{
                                    minHeight: "640px",
                                    maxHeight: "fit-content",
                                    // height: 87 * (data?.length > 9 ? 10 : data?.length + 0.8),
                                }}
                            >
                                <DataGrid
                                    autoHeight
                                    rows={data}
                                    columns={columns}
                                    // pageSize={50}
                                    pageSizeOptions={[100, 25, 10, 5]}
                                    checkboxSelection={false}
                                    disableSelectionOnClick={true}
                                />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </Item>
            </Container>
        </ApplyProvider>
    );
};

export default Search;
