import AccountCircle from "@mui/icons-material/AccountCircle";
import { NavLink, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Menu from "@mui/material/Menu";
import MobileMenu from "./MobileMenu";
import Box from "@mui/material/Box";
import React from "react";
import { useTranslation } from 'react-i18next';
import { Select } from "@mui/material";

export default function PrimaryAppBar() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openMobile, setOpenMobile] = React.useState(false);
    const { t, i18n } = useTranslation()
    const isMenuOpen = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const logOut = () => {
        sessionStorage.clear();
        handleMenuClose();
        navigate("/");
    };
    const menuId = "primary-search-account-menu";
    const renderMenu = (

        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={logOut}>{t("Logout")}</MenuItem>
        </Menu>
    );
    const onLanguage = (e) => {
        const lng = e.target.value;
        i18n.changeLanguage(lng);
        localStorage.setItem("lan", lng)
        window.location.reload()
    };
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <div className="burger" onClick={() => setOpenMobile(!openMobile)}>
                        <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 2 }}>
                            <MobileMenu />
                            {/* {openMobile ? <MobileMenu /> : ""} */}
                        </IconButton>
                    </div>
                    {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            TTS
          </Typography> */}
                    <Box>
                        <div className="nav_wrapper">
                            <NavLink className="nav" to="/search">
                                {t("Search")}
                            </NavLink>
                            <NavLink className="nav" to="/candidate">
                                {t("Candidate")}
                            </NavLink>
                            <NavLink className="nav" to="/form">
                                {t("Form")}
                            </NavLink>
                        </div>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Select style={{
                        width: "120px",
                        color: "white",
                        border: "none", // Border yo'q qilish
                    }} value={localStorage.getItem("lan") || "en"} size="medium" onChange={onLanguage} >
                    <MenuItem value="en">
                        en
                    </MenuItem>
                    <MenuItem value="ru">
                        ru
                    </MenuItem>
                    <MenuItem value="uz">
                        uz
                    </MenuItem>
                </Select>
                <Box sx={{ display: { md: "flex" } }}>
                    <IconButton size="large" aria-label="show 4 new mails" color="inherit"></IconButton>
                    <IconButton
                        className="AccountCircle"
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
            { renderMenu }
        </Box >
    );
}
