/* eslint-disable no-useless-escape */
import regex from "tts-hrp-val-lib";
import { array, boolean, number, object, string } from "yup";
import * as Yup from "yup";

import i18next from "i18next";
import resource from "./../../i18n/resources.json";
await resource;
// ---- ---- ---- APPLICANT ---- ---- ---- //

export const applicantSch = object({
    first_name: string().matches(regex.first_name, { message: "Invalid first name" }).required(i18next.t("First name is required")),
    last_name: string().matches(regex.last_name, { message: "Invalid last name" }).required(i18next.t("Last name is required")),
    middle_name: string().matches(regex.middle_name, { message: "Invalid middle name" }).nullable(),
    phone: string().matches(regex.phone, { message: "Invalid phone number" }).required(i18next.t("Phone is required")),
});

// ---- ---- ---- WORK HISTORY ---- ---- ---- //

export const workHistorySchema = object({
    work: array(
        object({
            name: string().matches(regex.company_name, { message: "Invalid name" }).required(i18next.t("Name is required")),
            position: string().matches(regex.work_position, { message: "Invalid position" }).required(i18next.t("Position is required")),
            start_date: Yup.date().max(new Date(), "Future date not allowed").typeError("Invalid start date").required(i18next.t("Start date is required")),
            end_date: Yup.date()
                .required(i18next.t("End date is required"))
                .when("start_date", (start_date, yup) => start_date && yup.min(start_date, "End date cannot be before start date")),
            email: string().email("Invalid email").required(i18next.t("Email is required")),
            phone: string().matches(regex.phone, { message: "Invalid phone number" }).required(i18next.t("Your phone is required")),
            website: string().matches(regex.website, { message: "Invalid website" }).nullable(),
            is_complete: boolean(),

            address: object({
                country: string().required(i18next.t("Country is required")),
                address1: string().matches(regex.address1, { message: "Invalid address 1" }).required(i18next.t("Address 1 is required")),
                address2: string().matches(regex.address2, { message: "Invalid address 2" }).nullable(),
                city: string().matches(regex.city, { message: "Invalid city" }).required(i18next.t("City is required")),
                region: string().required(i18next.t("Region is required")),
                zip_code: string().matches(regex.zip_code, { message: "Invalid ZipCode" }).nullable(),
            }),

            supervisor: Yup.object().when("is_complete", {
                is: (is_complete) => is_complete === true,
                then: Yup.object().shape({
                    name: string().matches(regex.full_name, { message: "Invalid name" }).required(i18next.t("Name is required")),
                    last_name: string().matches(regex.last_name, { message: "Invalid last name" }).required(i18next.t("Last name is required")),
                    position: string().matches(regex.work_position, { message: "Invalid position" }).required(i18next.t("Position is required")),
                    phone: string().matches(regex.phone, { message: "Invalid phone number" }).required(i18next.t("Supervisor phone is required")),
                    email: string().required(i18next.t("Supervisor email is required")).email("Invalid email"),
                }),
            }),

            references: Yup.array().when("is_complete", {
                is: (is_complete) => is_complete === true,
                then: array(
                    object({
                        first_name: string().matches(regex.first_name, { message: "Invalid First name" }).required(i18next.t("First name is required")),
                        last_name: string().matches(regex.last_name, { message: "Invalid name" }).required(i18next.t("Last name is required")),
                        position: string().matches(regex.work_position, { message: "Invalid position" }).required(i18next.t("Position is required")),
                        phone: string().matches(regex.phone, { message: "Invalid phone number" }).required(i18next.t("Supervisor phone is required")),
                        email: string().email("Invalid email").required(i18next.t("Supervisor email is required")),
                    })
                ),
            }),
        })
    )
        .min(1, "You need to provide at least 1 Work history")
        .max(5, "You can only provide 5 Work history"),
});

// ---- ---- ---- EDUCATION ---- ---- ---- //

export const educationSchema = object({
    educations: array(
        object({
            university: string().required(i18next.t("University is required")),
            major: string().matches(regex.major, { message: "Invalid major" }).required(i18next.t("Major is required")),
            is_complete: boolean().required(i18next.t("required")),
            years_of_edu: string().matches(/^\d+$/, { message: "Years of education is invalid" }).required(i18next.t("Years of education is required")),
            degree_type: string().required(i18next.t("Degree type is required")),
            start_date: Yup.date().max(new Date(), "Future date not allowed").typeError("Invalid start date date").required(i18next.t("Date of enrolment is required")),
           
        })
    )
        .min(1, "You need to provide at least 1 education")
        .max(3, "You can only provide 5 education"),
});

// ---- ---- ---- SKILL ---- ---- ---- //

export const skillSch = object({
    name: string().required(i18next.t("Skill is required")),
    level: string().required(i18next.t("Level is required")),
});

export const resumeSch = object({
    file: string().required("File is required"),
});
