import { emptySkill, emptyEducation, emptyWork } from "./EmptyData";
import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import StepperCard from "../../plugin/StepperCard";
import { toast } from "react-toastify";
import Input from "../../plugin/Input";
import Upload from "./Upload";
import REACT_APP from "../../environment";
import axios from "axios";
import { useTranslation } from "react-i18next";

import i18next from "i18next";
import resource from "./../../i18n/resources.json";
await resource;
const Data = () => {
    const e_name = (id, st) => `education[${id}].${st}`;
    const w_name = (id, st) => `work_history.companies[${id}].${st}`;
    const w_name2 = (id1, id2, st) => `work_history.companies[${id1}].references[${id2}].${st}`;

    const [emptyApplicant] = useState({
        middle_name: "",
        first_name: "",
        last_name: "",
        phone: "",
        work_history: { companies: [emptyWork] },
        educations: [emptyEducation],
        skills: [emptySkill],
    });
    const [me, setMe] = useState([]);
    const [skills, setSkills] = useState([]);
    const [address, setAddress] = useState();
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [education, setEducation] = useState([]);
    const [supervisor, setSupervisor] = useState();
    const [references, setReferences] = useState([]);
    const { t } = useTranslation();
    const getMe = async () => {
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/applicant/me`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => setMe(res.data.data))
            .catch((err) => toast.error("An error occurred"));
    };

    const getHistory = async () => {
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/applicant/history`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setHistory(res.data.data.workHistories);
                res.data.data.workHistories.forEach((it) => {
                    setAddress(it.address);
                    setSupervisor(it.supervisor);
                    setReferences(it.references);
                });
            })
            .catch((err) => toast.error("An error occurred"));
    };

    const getEdu = async () => {
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/applicant/edu`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => setEducation(res.data.data.educations))
            .catch((err) => toast.error("An error occurred"));
    };

    const getSkill = async () => {
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/applicant/skill`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => setSkills(res.data.data.skills))
            .catch((err) => toast.error("An error occurred"));
    };

    const getAll = async () => {
        setLoading(true);
        await getMe();
        await getHistory();
        await getEdu();
        await getSkill();
        setLoading(false);
    };

    useEffect(() => {
        getAll();
    }, []);

    return (
        <>
            {loading ? (
                <div className="c_i_center">
                    <CircularProgress />
                </div>
            ) : (
                <StepperCard title={t("Applicant")}>
                    <Formik initialValues={emptyApplicant} enableReinitialize>
                        <Form autoComplete="off">
                            <Grid container direction="column" spacing={2}>
                                <Grid item container spacing={2} xs={12} sm="auto">
                                    {me.first_name && <Input sm={3} name="first_name" label={t("First Name")} val={me.first_name} load={true} />}
                                    {me.last_name && <Input sm={3} name="last_name" label={t("Last Name")} val={me.last_name} load={true} />}
                                    {me.middle_name ? <Input sm={3} name="middle_name" label={t("Middle Name")} val={me.middle_name} load={true} /> : null}
                                    {me.phone && <Input sm={3} name="phone" label={t("Phone number")} val={me.phone} load={true} />}

                                    <Grid item xs={12}>
                                        <h5 className="text-center mt-3">{t("Work")}</h5>
                                    </Grid>
                                    {history.map((item, index) => (
                                        <FieldArray name="work_history.companies" key={index}>
                                            <Grid container item key={index} spacing={2}>
                                                <Input sm={4} name={w_name(index, "name")} label={t("Name")} val={item.name} load={true} />
                                                <Input sm={4} name={w_name(index, "position")} label={t("Position")} val={item.position} load={true} />
                                                <Input sm={4} name={w_name(index, "email")} label={t("Email")} val={item.email} load={true} />
                                                <Input sm={3} name={w_name(index, "phone")} label={t("Phone number")} val={item.phone} load={true} />
                                                <Input sm={3} name={w_name(index, "website")} label={t("Website")} val={item.website} load={true} />
                                                <Input
                                                    sm={3}
                                                    name={w_name(index, "start_date")}
                                                    label={t("Start date")}
                                                    val={item.start_date?.slice(0, 10)}
                                                    load={true}
                                                />
                                                <Input
                                                    sm={3}
                                                    name={w_name(index, "end_date")}
                                                    label={t("End date")}
                                                    val={item.end_date?.slice(0, 10)}
                                                    load={true}
                                                />

                                                <Grid item xs={12}>
                                                    <h6>{t("Address")}</h6>
                                                </Grid>
                                                <Input sm={4} name={w_name(index, "address.city")} label={t("City")} val={address.city} load={true} />
                                                <Input sm={4} name={w_name(index, "address.region")} label={t("Region")} val={address.region} load={true} />
                                                <Input sm={4} name={w_name(index, "address.country")} label={t("Country")} val={address.country} load={true} />
                                                <Input
                                                    sm={4}
                                                    name={w_name(index, "address.zip_code")}
                                                    label={t("Zip Code")}
                                                    val={address.zip_code}
                                                    load={true}
                                                />
                                                <Input
                                                    sm={4}
                                                    name={w_name(index, "address.address1")}
                                                    label={t("Address 1")}
                                                    val={address.address1}
                                                    load={true}
                                                />
                                                <Input
                                                    sm={4}
                                                    name={w_name(index, "address.address2")}
                                                    label={t("Address 2")}
                                                    val={address.address2}
                                                    load={true}
                                                />

                                                {supervisor && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <h6>{t("Supervisor")}</h6>
                                                        </Grid>
                                                        <Input
                                                            sm={3}
                                                            name={w_name(index, "supervisor.name")}
                                                            label={t("Supervisor name")}
                                                            val={supervisor.name}
                                                            load={true}
                                                        />
                                                        <Input
                                                            sm={3}
                                                            name={w_name(index, "supervisor.position")}
                                                            label={t("Supervisor position")}
                                                            val={supervisor.position}
                                                            load={true}
                                                        />
                                                        <Input
                                                            sm={3}
                                                            name={w_name(index, "supervisor.phone")}
                                                            label={t("Supervisor phone")}
                                                            val={supervisor.phone}
                                                            load={true}
                                                        />
                                                        <Input
                                                            sm={3}
                                                            name={w_name(index, "supervisor.email")}
                                                            label={t("Supervisor email")}
                                                            val={supervisor.email}
                                                            load={true}
                                                        />
                                                    </>
                                                )}
                                                {references.length !== 0 ? (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <h6>{t("References")}</h6>
                                                        </Grid>
                                                        {references.map((subval, idx) => (
                                                            <React.Fragment key={idx}>
                                                                <Input
                                                                    sm={6}
                                                                    name={w_name2(index, idx, "first_name")}
                                                                    val={subval.first_name}
                                                                    label={t("First Name")}
                                                                    load={true}
                                                                />
                                                                <Input
                                                                    sm={6}
                                                                    name={w_name2(index, idx, "last_name")}
                                                                    val={subval.last_name}
                                                                    label={t("Last Name")}
                                                                    load={true}
                                                                />
                                                                <Input
                                                                    sm={4}
                                                                    name={w_name2(index, idx, "position")}
                                                                    val={subval.position}
                                                                    label={t("Position")}
                                                                    load={true}
                                                                />
                                                                <Input
                                                                    sm={4}
                                                                    name={w_name2(index, idx, "email")}
                                                                    val={subval.email}
                                                                    label={t("Email")}
                                                                    load={true}
                                                                />
                                                                <Input
                                                                    sm={4}
                                                                    name={w_name2(index, idx, "phone")}
                                                                    val={subval.phone}
                                                                    label={t("Phone")}
                                                                    load={true}
                                                                />
                                                            </React.Fragment>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </Grid>
                                        </FieldArray>
                                    ))}

                                    {education.length !== 0 ? (
                                        <>
                                            <Grid item xs={12}>
                                                <h5 className="text-center mt-3">{t("Education")}</h5>
                                            </Grid>
                                            {education.map((edu, index) => (
                                                <Grid container item key={index} spacing={2}>
                                                    <Input sm={4} name={e_name(index, "university")} val={edu.university} label={t("University")} load={true} />
                                                    <Input sm={4} name={e_name(index, "major")} val={edu.major} label={t("Major")} load={true} />
                                                    <Input
                                                        sm={4}
                                                        name={e_name(index, "degree_type")}
                                                        val={edu.degree_type}
                                                        label={t("Degree type")}
                                                        load={true}
                                                    />
                                                    <Input
                                                        sm={3}
                                                        name={e_name(index, "years_of_edu")}
                                                        val={edu.years_of_edu}
                                                        label={t("Years of education")}
                                                        load={true}
                                                    />
                                                    <Input
                                                        sm={3}
                                                        name={e_name(index, "is_complete")}
                                                        val={t(String(edu.is_complete?.toString()))}
                                                        label={t("Is completed")}
                                                        load={true}
                                                    />
                                                    <Input
                                                        sm={3}
                                                        name={e_name(index, "start_date")}
                                                        val={edu.start_date?.slice(0, 10)}
                                                        label={t("Start date")}
                                                        load={true}
                                                    />
                                                    <Input
                                                        sm={3}
                                                        name={e_name(index, "end_date")}
                                                        val={edu.end_date?.slice(0, 10)}
                                                        label={t("End date")}
                                                        load={true}
                                                    />
                                                </Grid>
                                            ))}
                                        </>
                                    ) : null}

                                    {skills.length !== 0 ? (
                                        <>
                                            <Grid item xs={12}>
                                                <h5 className="text-center mt-3">{t("Skills")}</h5>
                                            </Grid>
                                            {skills.map((item, index) => (
                                                <Grid container item key={index} spacing={2}>
                                                    <Input sm={6} name={`skills[${index}].name`} label={t("Name")} val={item?.name} load={true} />
                                                    <Input sm={6} name={`skills[${index}].level`} label={t("Level")} val={item.level} load={true} />
                                                </Grid>
                                            ))}
                                        </>
                                    ) : null}
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </StepperCard>
            )}
        </>
    );
};

export default Data;
