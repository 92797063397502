/* eslint-disable react-hooks/exhaustive-deps */
import setCookie from "../../../hooks/cookie/setCookie";
import getCookie from "../../../hooks/cookie/getCookie";
import StepperCard from "../../../plugin/StepperCard";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import Buttons from "../../../plugin/Button";
import { skillSch } from "../validateSchema";
import Select from "../../../plugin/Select";
import Input from "../../../plugin/Input";
import { TextField } from "formik-material-ui";
import { toast } from "react-toastify";
import { Form, Field, Formik } from "formik";
import REACT_APP from "../../../environment";
import { Button, CircularProgress, Grid, MenuItem } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";

import i18next from "i18next";
import resource from "./../../../i18n/resources.json";
await resource;
const colorA = "rgba(139, 139, 139, 0.45) 0px 4px 4px -2px,rgba(101, 101, 101, 0.48) 0px 0px 1px 1px";
const colorB = "rgba(255, 0, 0, 0.45) 0px 3px 3px -2px,rgba(255, 0, 0, 0.38) 0px 0px 1px 1px";

export default function Skills({ next }) {
    const { t } = useTranslation()

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [degree, setDegree] = useState([{ id: 1, degree: "beginner" }]);

    const qy = document.querySelector.bind(document);
    const secretCookie = sessionStorage?.getItem("userEmail");
    const myObject = JSON.parse(getCookie(secretCookie));
    console.log(myObject);

    const getDegree = () => {
        axios(`${REACT_APP.URL}/api/hrp/v1/applicant/levels`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => setDegree(res.data.data.levels))
            .catch((err) =>toast.error(i18next.t("An error occurred")));
    };

    function addSkills(value) {
        if (value.errors) return;
        if (items.find((e) => e.name === value.name)) {
            toast.warning(i18next.t("Already exist!"));
            return;
        }
        setItems((prev) => [...prev, value]);
    }

    const showBtn = (e) => {
        const atr = e.target.childNodes[1];
        if (atr) {
            atr.style.display === "block" ? (atr.style.display = "none") : (atr.style.display = "block");
        }
    };

    const removeItem = (e) => {
        const txt = e.target.parentElement.parentElement.childNodes[0];
        setItems(items.filter((itm) => itm.name !== txt.textContent));
        txt.parentElement.childNodes[1].style.display = "none";
    };

    const handleSubmit = async () => {
        if (items.length === 0) {
            qy("#lbl-list").style.display = "block";
            qy(".card-brd").style.boxShadow = colorB;
            return;
        }
        await setLoading(true);
        myObject.skills = items;
        setCookie(secretCookie, JSON.stringify(myObject));
        setTimeout(() => {
            setLoading(false);
            next();
        }, 1000);
    };

    useEffect(() => {
        if (items.length !== 0) {
            qy("#lbl-list").style.display = "none";
            qy(".card-brd").style.boxShadow = colorA;
        } else {
            if (qy("#lbl-list")) qy("#lbl-list").style.display = "block";
            if (qy(".card-brd")) qy(".card-brd").style.boxShadow = colorB;
        }
    }, [items]);

    useEffect(() => {
        const myObject = JSON.parse(getCookie(secretCookie));
        if (myObject.skills) next();

        getDegree();
        if (qy("#lbl-list")) qy("#lbl-list").style.display = "none";
        if (qy(".card-brd")) qy(".card-brd").style.boxShadow = colorA;
    }, []);

    return (
        <StepperCard title={t("Skills")}>
            <Formik
                initialValues={{ name: "", level: "" }}
                validationSchema={skillSch}
                onSubmit={(values, { resetForm }) => {
                    addSkills(values);
                    resetForm({ values: "" });
                }}
            >
                {({ values, errors }) => (
                    <Form autoComplete="off">
                        <Grid item container spacing={2} xs={12} className="d-flex justify-content-center">
                            <Input sm={5} name="name" label={t("Skill")} load={loading} />
                            {/* <Select sm={5} name="level" label={t("Your degree")} option={degree} load={loading} /> */}
                            <Grid item xs={12} sm={5}>

                                <Field sm={5} fullWidth variant="standard" component={TextField} select name="level" label={t("Your degree")}>
                                    {degree.map((el) => (
                                        <MenuItem key={el.id} value={el.id}>
                                            {t(el.degree)}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>

                            <Buttons sm={2} type="submit" cls="float-end" st={false} click={() => addSkills({ values, errors })} name={t("Add")} load={loading} />
                        </Grid>
                    </Form>
                )}
            </Formik>

            <div className="col-md-12 my-4">
                <label htmlFor="list" className="form-label">
                    {t("Lists")}
                </label>
                <div className="card-brd">
                    {items.map((el, i) => (
                        <span
                            key={i}
                            onClick={(e) => showBtn(e)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={el.level}
                            className="bg-secondary text-white m-1 rounded float-start d-flex"
                        >
                            {el.name}
                            <DeleteIcon className="bi-trash" style={{ display: "none" }} onClick={removeItem} />
                        </span>
                    ))}
                </div>
                <label id="lbl-list" className="text-danger my-1">
                    {t("Please required list")}
                </label>
            </div>
            <Buttons name={t("Save")} cls="float-end" click={handleSubmit} load={loading} />
        </StepperCard>
    );
}
