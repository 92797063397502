/* eslint-disable react-hooks/exhaustive-deps */
import getCookie from "../../../hooks/cookie/getCookie";
import setCookie from "../../../hooks/cookie/setCookie";
import StepperCard from "../../../plugin/StepperCard";
import { applicantSch } from "../validateSchema";
import Buttons from "../../../plugin/Button";
import Input from "../../../plugin/Input";
import { applicant } from "../EmptyData";
import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Personal({ next }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const secretCookie = sessionStorage?.getItem("userEmail");

    const myObject = { personal: "" };

    const submit = (values) => {
        setLoading(true);
        if (!values.middle_name) values.middle_name = undefined;
        myObject.personal = values;
        setCookie(secretCookie, JSON.stringify(myObject));
        setTimeout(() => {
            setLoading(false);
            next();
        }, 1000);
    };

    useEffect(() => {
        if (getCookie(secretCookie)) {
            const myObject = JSON.parse(getCookie(secretCookie));
            if (myObject.personal !== "") next();
        }
    }, []);

    return (
        <StepperCard title={t("Personal")}>
            <Formik initialValues={applicant} validationSchema={applicantSch} onSubmit={(values) => submit(values)}>
                <Form autoComplete="off">
                    <Grid item container spacing={3} xs={12}>
                        <Input sm={6} name="first_name" label={t("First Name")} load={loading} />
                        <Input sm={6} name="last_name" label={t("Last Name")} load={loading} />
                        <Input sm={6} name="middle_name" label={t("Middle Name")} load={loading} />
                        <Input sm={6} name="phone" label={t("Phone")} load={loading} />
                        <Buttons type="submit" name={t("Save")} cls="float-end" load={loading} />
                    </Grid>
                </Form>
            </Formik>
        </StepperCard>
    );
}
