import { CardContent } from "@mui/material";

const StepperCard = ({ title, children }) => {
    return (
        <div className="d-flex justify-content-center flex-column container py-3">
            <div className="py-2">
                <div className="card">
                    <div className="card-header">
                        <h5 className="text-center">{title}</h5>
                    </div>
                    <CardContent style={{ padding: "30px" }}>{children}</CardContent>
                </div>
            </div>
        </div>
    );
};

export default StepperCard;
