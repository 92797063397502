import OnboardingStepper from "./Stepper/OnboardingStepper";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import Data from "./Data";
import REACT_APP from "../../environment";
import axios from "axios";

const Index = () => {
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(true);

    const getLicense = async () => {
        setLoading(true);
        setData(false);
        await axios(`${REACT_APP.URL}/api/hrp/v1/applicant/me/all`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setData(true);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    useEffect(() => {
        getLicense();
    }, []);

    return (
        <>
            {loading ? (
                <div className="c_i_center">
                    <CircularProgress />
                </div>
            ) : data ? (
                <Data />
            ) : (
                <OnboardingStepper />
            )}
        </>
    );
};

export default Index;
