import * as React from "react";
// import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function BasicMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { t } = useTranslation()

    return (
        <div>
            {/* <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      > */}
            <MenuIcon
                style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "3px",
                }}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            />
            {/* </Button> */}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <NavLink className="nav2" to="/search">
                    <MenuItem onClick={handleClose}>{t("Search")}</MenuItem>
                </NavLink>
                <NavLink className="nav2" to="/candidate">
                    <MenuItem onClick={handleClose}>{t("Candidate")}</MenuItem>
                </NavLink>
                <NavLink className="nav2" to="/form">
                    <MenuItem onClick={handleClose}>{t("Form")}</MenuItem>
                </NavLink>
            </Menu>
        </div>
    );
}
