import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import { Field } from "formik";
import { useTranslation } from "react-i18next";

const Select = (props) => {
    const { sm, name, label, option, load } = props;
    const { t } = useTranslation();

    return (
        <Grid item xs={12} sm={sm}>
            <Field select fullWidth name={name} size="small" label={label} disabled={load} component={TextField}>
                {option.map((item, index) => (
                    <MenuItem value={item?.name || item?.id} key={item?.id}>
                        {t(item?.name)}
                        {item?.degree}
                    </MenuItem>
                ))}
            </Field>
        </Grid>
    );
};

export default Select;
